<template>
    <div v-if="subscriptions.length"  class="container-wrapper">
      <div v-for="item in subscriptions" :key="item.id" class="package">
        <subscription-component :item="item"/>
      </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import SubscriptionComponent from "./SubscriptionComponent";

export default {
  name: "SubscriptionsComponent",
  components: {SubscriptionComponent},
  props: {
    limit: {
      default: null,
      type: Number,
      required: true,
    }
  },
  methods: {
    ...mapActions(['GET_SUBSCRIPTIONS_CACHED']),
  },
  computed: {
    ...mapState({
      subscriptions: state => state.payment.subscriptionsList,
    }),
  },
  mounted() {
    this.GET_SUBSCRIPTIONS_CACHED({limit: this.limit});
  }
}
</script>

<style lang="scss" scoped>
  .container-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
    gap: .5rem;
  }

  .package {
    width: 100%;
    display: flex;
  }
</style>